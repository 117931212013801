/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import Breadcrumb from "@/components/Common/Breadcrumb";
import Layout from "@/components/Layout";
import SEO from "@/components/seo";
import React, { useEffect } from "react";
import { useLocation } from "@reach/router";
import { Link, useStaticQuery, graphql, navigate } from "gatsby";
import { type Props } from "@/components/Media/types";
import Partners from "@/components/Home/Partners";
import { useViewport } from "@/hooks/useViewport";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";

const SingleGallery = ({ params }: any) => {
  const { id } = params;
  const data: Props = useStaticQuery(graphql`
    query {
      allGalleryJson {
        nodes {
          id
          label
          captionImagenName
          album {
            name
            width
            height
          }
        }
      }
    }
  `);

  const { isMobile } = useViewport();
  const gallery = data.allGalleryJson.nodes.filter((el) => el.id === id)[0];

  const images = gallery
    ? gallery.album.map((e) => ({
        original: e.name,
        thumbnail: e.name,
        caption: gallery.label,
        width: e.width,
        height: e.height,
      }))
    : [];

  useEffect(() => {
    if (!id || !gallery) {
      navigate("/media");
    }
  }, []);

  return (
    <Layout>
      <SEO title="Gallery" canonical={`${useLocation().host}`} />
      <Breadcrumb
        name="Image Gallery"
        pathName="Image Gallery"
        imgUrl="/images/media-bg.png"
      />
      <div className="container mt--40">
        <div
          className={`d-flex justify-content-between align-items-center ${
            isMobile ? "flex-column" : ""
          }`}
        >
          <h2 className="label-g-p">{gallery && gallery.label}</h2>
          <Link className="btn-back-d" to="/media">
            Back to Media
          </Link>
        </div>
        <div className="container p-b-gallery-w">
          <div className="gallery-wrapper-p">
            {images && (
              <Gallery withCaption>
                {images.map((e, indx) => {
                  return (
                    <Item
                      key={`sng-s-${indx}`}
                      original={`/images/gallery/${String(e.original)}`}
                      thumbnail={`/images/gallery/${String(e.thumbnail)}`}
                      caption={e.caption}
                      width={e.width < 700 ? e.width * 5 : e.width}
                      height={e.width < 700 ? e.height * 5 : e.height}
                    >
                      {({ ref, open }) => (
                        <img
                          ref={ref as React.MutableRefObject<HTMLImageElement>}
                          onClick={open}
                          src={`/images/gallery/${String(e.thumbnail)}`}
                          alt="Gallery Image"
                        />
                      )}
                    </Item>
                  );
                })}
              </Gallery>
            )}
          </div>
        </div>
        <Partners />
      </div>
    </Layout>
  );
};

export default SingleGallery;
